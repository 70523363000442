.voucher {
  width: 970px;
}
.voucher-block {
  position: relative;
  width: 970px;
}

.voucher-block > .parent-name {
  position: absolute;
  top: 269px;
  left: 604px;
  color: orange;
  font-weight: bold;
  font-size: 20px;
}

.voucher-block > .student-name {
  position: absolute;
  top: 297px;
  left: 484px;
  color: orange;
  font-weight: bold;
  font-size: 20px;
}

.voucher-block > .phone {
  position: absolute;
  top: 324px;
  left: 510px;
  color: orange;
  font-weight: bold;
  font-size: 20px;
}

.voucher-block > .registered-date {
  position: absolute;
  top: 324px;
  left: 836px;
  color: orange;
  font-weight: bold;
  font-size: 20px;
}
